<template>
   <div class="ks-body position-relative flex-1 m-4 p-3">
      <div class="d-flex">
         <!-- <h3>CRF  <span style="color:red">{{(routeName == 'active' ? form.cr_no : '')}}</span></h3>  -->
         <h3>CRF  <span style="color:red">{{(form.cr_no ? form.cr_no : '')}}</span></h3> 
         
         <h4 class="ml-auto text-center">{{form.cr_date ? form.cr_date : date}}</h4>
         <h4 class="ml-auto text-center name">{{me.name}}</h4>
         <div class="ml-auto">
          <span class="badge badge-pill badge-dark">Create</span>

         </div>
      </div>
        <div class="d-flex flex-column scroll"  style="border-top: 1px solid rgba(57, 81, 155, .2);">
           <div class="d-flex mb-5 mt-3 con1">
               <div class="d-flex flex-column">
                  <strong>Department</strong>
                  <span v-if="editDetails">
                     <input type="text" class="form-control" v-model="form.cr_dept" disabled>
                  </span>
                  <span v-else>
                     <span v-if="loading">
                        <select name="" id="" class="form-select"  style="width:400px">
                        <option>Loading...</option>
                        </select>
                     </span>
                     <span v-else>
                           <select name="" id="" class="form-select"  style="width:400px" v-model="form.cr_dept">
                              <option :value="dept.department" v-for="(dept,index) in department" :key="index"> {{dept.department}}</option>
                           </select>
                     </span>
                  
                  </span>
                
               </div>
               <div class="d-flex datecon">
                  <div class="d-flex flex-column ml-5"> 
                     <strong> Date Needed</strong>
                     <input type="date" class="form-control" v-model="form.date_needed">
                  </div>
                  <div class="d-flex flex-column ml-5 cur">
                     <strong>Currency</strong>
                     <select name="" id="" class="form-select " style="width:150px" v-model="form.currency">
                        <option value="PHP" >PHP</option>

                        <option value="US$">USD</option>
                     </select>
                  </div>

               </div>
        
               <div class="d-flex flex-column ml-5">
                  <strong>Amount Requested</strong>
                  <!-- <input type="text" class="form-control" v-model="form.amount_requested" autocomplete="off"> -->
                  <input type="number" @change="setTwoNumberDecimal" min="0" max="10" step="0.25" placeholder="0.00" class="form-control" v-model="form.amount_requested"/>
               </div>
           </div>
           <div class="d-flex mb-5 con1">
               <div class="d-flex flex-column">
                  <strong>MIAF Reference</strong>
                  <input type="text" class="form-control" v-model="form.miv_no">
               </div>
               <div class="d-flex flex-column ml-5">
                  <strong>PA Number</strong>
                  <input type="text" class="form-control" v-model="form.pa_number">
               </div>
               <div class="d-flex flex-column ml-5">
                  <strong>DN Number</strong>
                  <input type="text" class="form-control" v-model="form.dn_number">
               </div>
               <div class="d-flex flex-column ml-5">
                  <strong>JO Number</strong>
                  <input type="text" class="form-control" v-model="form.jo_number">
               </div>
           </div>
           <div class="d-flex mb-5 justify-content-between con1">
               <div class="d-flex flex-column">
                  <strong>Requestor</strong>
                     <span v-if="loading">
                        <select name="" id="" class="form-select"  style="width:400px">
                        <option>Loading...</option>
                        </select>
                     </span>
                     <span v-else>
                            <select name="" id="" class="form-select"  style="width:400px" v-model="form.requestor">
                                 <option :value="requestor.user_id" v-for="(requestor,index) in requestors.users" :key="index"> {{requestor.user_id}}</option>
                              </select>
                     </span>
               </div>
               <div class="d-flex flex-column ml-5">
                  <strong>Payee Code</strong>
                  
                  <input type="text" class="form-control" v-model="form.payee_code" disabled>
               </div>
               <div class="d-flex flex-column ml-5">
                  <strong>Payee</strong>
                  <div class="d-flex">
                     <input type="text" class="form-control payee" v-model="form.payee" disabled>
                     <button class="btn btn-secondary"  @click="openModal()">Select</button>
                  </div>
           
               </div>
               <div class="d-flex flex-column ml-5">
                  
               </div>
           </div>
           <div class="d-flex flex-column mb-5">
               <strong>Particulars</strong>
              <textarea name="Text1" cols="10" rows="5" class="form-control" placeholder="Input Particulars" 
                v-model="form.particulars"></textarea>
           </div>

         <div class="ks-footer ks-header mobile">
            <div class="d-flex">
               <span v-if="details">
                         <span>
                  <button class="btn btn-primary mr-3" @click="updateCRF()" v-if="!update_load">Update</button>
                   <button class="btn btn-primary mr-3" v-else><span class="spinner-border spinner-border-sm"></span></button>
               </span>
               <span>
                        <button class="btn btn-success mr-3"  @click="Send(form)" v-if="!send_loading"> <i class="fa-sharp fa-solid fa-paper-plane fa-xl"></i></button>
                        <button class="btn btn-success mr-3"  v-else><span class="spinner-border spinner-border-sm"></span> </button>
               </span>
                  <button class="btn btn-secondary mr-3" @click="clear" v-if="details">CREATE NEW</button>
               <button class="btn btn-secondary ml-3" @click="Close()" v-if="editDetails">Close</button>

               </span>
               <span v-else>
                  <button class="btn btn-success mr-3"  @click="save()" v-if="!create_loading"><i class="fa-solid fa-floppy-disk"></i></button>
                  <button class="btn btn-success mr-3"  v-else><span class="spinner-border spinner-border-sm"></span></button>
               </span>

               <button class="btn btn-secondary " @click="clear" v-if="!details">Clear</button>

            </div>
         </div>

         
        </div>
   </div>
   <div class="ks-footer ks-header web">
         <div class="d-flex">
            <span v-if="details">
               <span>
                  <button class="btn btn-primary mr-3" @click="updateCRF()" v-if="!update_load">Update</button>
                   <button class="btn btn-primary mr-3" v-else><span class="spinner-border spinner-border-sm"></span></button>
               </span>
               <span>
                        <button class="btn btn-success mr-3"  @click="Send(form)" v-if="!send_loading" :disabled="true"> 
                           <!-- <i class="fa-sharp fa-solid fa-paper-plane fa-xl"></i> -->
                           Send for Approval
                        </button>
                        <button class="btn btn-success mr-3"  v-else><span class="spinner-border spinner-border-sm"></span> </button>
               </span>
   
                     <button class="btn btn-secondary mr-3" @click="clear" v-if="details && !editDetails" >CREATE NEW</button>

                  <button class="btn btn-secondary ml-3" @click="Close()"  v-if="editDetails">Close</button>

            </span>
            <span v-else>
               <button class="btn btn-success mr-3"  @click="save()" v-if="!create_loading">
                     <!-- <i class="fa-solid fa-floppy-disk"></i> -->
                     Save
                  </button>
               <button class="btn btn-success mr-3"  v-else><span class="spinner-border spinner-border-sm"></span></button>
            </span>
            <!-- <button class="btn btn-secondary mr-3" disabled>Send</button> -->
            <button class="btn btn-secondary " @click="clear" v-if="!details">Clear</button>

            </div>
   </div>

        <payee-modal :show="show"  :payees="payees" :load="payeeLoad"  />
</template>
<script>
import payee_modal from '@/views/crf/crf_payee_modal.vue'

export default {
   props: ['editDetails'],
   components: {
      'payee-modal' : payee_modal
   },
   data(){
      return{
         send_loading :false,
         show : false,
         payeeLoad: false,
         loading: false,
         create_loading: false,
         update_load: false,
         details : false,
         form: {},
         department: {},
         requestors: {},
         me: {},
         payees: {},
         date : '',
         data: {
            page: 1,
            search: ''
         },

      }
   },
   computed : {
      routeName(){
         return this.$route.name
      },
      SendValidation(){
         if(this.form.miv_no > 0 || this.form.pa_number ||  this.form.dn_number ||  this.form.jo_number)return true;
            
                return false;
        }
   },
   methods : {
      setTwoNumberDecimal(event){
         event.value = parseFloat(event.value).toFixed(2);
      },
      async fetch(){
         this.loading =  !this.loading
         this.me = await this.API.ME()
         this.department = await this.API.fetchdepartment()
         this.requestors = await this.API.fetchUsers()
         this.initload()

         this.loading = !this.loading
         // console.log(this.me);
      },
      initload(){
         // this.date = this.API.getDate()
         this.date = this.HELPER.formatedDate();
         // this.form.date_needed = this.API._initDate(0)
         this.form.date_needed = this.HELPER.dateToday()
         this.form.currency = 'PHP'
         if(!this.editDetails){
            this.form.requestor = this.requestors.default

         }
      },
      async save(){
         this.create_loading = !this.create_loading
          await this.API.saveCRF(this.form)
          .then(res => {
            // this.clear()
            this.details = true
            this.$jlalert.show(res.data.message, res.status !== 200);
            this.form.cr_no = res.data.cr_no
            // console.log(res);
            this.create_loading = !this.create_loading

             
          })
          .catch(err => {
              this.$jlalert.show(err);
               this.create_loading = !this.create_loading

          })
      },

      async updateCRF(){
         this.update_load = !this.update_load

         await this.API.updateCRF(this.form)
         .then(res => {

            this.$jlalert.show(res.data, res.status !== 200)
            // console.log(res.data)
            this.update_load = !this.update_load
            this.Close()
         })
         .catch(err => {
            this.$$jlalert.show(err)
            // console.log(err)
         this.update_load = !this.update_load

         })
      },
      async Send(data){
            this.send_loading = !this.send_loading
        await this.API.sendCRF(data)
            .then( res => {
               //  this.open()
                this.$jlalert.show(res.data, res.status != 200);
                this.send_loading = !this.send_loading

            })
            .catch(err => {
                this.$jlalert.show(err)
                this.send_loading = !this.send_loading

            })
        },
      async fetchpayee(){
         this.payeeLoad = true
         this.payees =  await this.API.fetchpayee(this.data)
         this.data.search = ''

         // console.log(this.payees)
         this.payeeLoad = false
      },

      openModal(){
         this.fetchpayee()
         this.show = !this.show
      },

      load(){
         if(this.editDetails != undefined){
            this.form = this.editDetails
       
            // let amount_requested = this.form.amount_requested.replace(',','');
            this.form.amount_requested =  this.HELPER.reformat(this.form.amount_requested);
            this.details = true
            // this.form.date_needed = this.API.convertDate(this.form.date_needed)
            
            this.form.date_needed = this.$moment(this.form.date_needed).format('YYYY-MM-DD');
         }
      },
      Close(){
         this.clear()
         this.$emitter.emit('close')
      },
      clear(){
         this.details = false
         this.form = {}
         this.initload()
      },
      Myfunction(){
         console.log(this.form.date_needed)
      },
   //    reformat(data){
   //      let amount =  data;
   //      let length = data.length

   //      length = length / 6
        
   //      if(length > 0){
   //          for(var i = 0; i < length ; i ++){
   //              amount = amount.replace(',','');
   //          }

   //      }

   //      return amount * 1;
   //  }
   },

   mounted(){
     let instance = this;
        this.$emitter.on('prev', function() {
            instance.data.page --
            instance.fetchpayee()
        })
        this.$emitter.on('next', function(){
            instance.data.page ++
            instance.fetchpayee()
        })
         this.$emitter.on('search', function(search){
            instance.data.search = search
   
            instance.fetchpayee()
        })
        this.$emitter.on('selected', function(index){
            instance.form.payee_code = instance.payees.payee[index].payee_code
            instance.form.payee = instance.payees.payee[index].payee_name
        })
         this.$emitter.on('refresh', function(){
            // instance.data.search = search
            instance.data.search = ''
            instance.fetchpayee()
        })

      this.load()
      this.fetch()
   },
   watch : {
      'routeName': function(data){
         switch(data){
            case 'create':
               this.clear()
               this.fetch()

            break
            case 'active':
               this.load()
            break
            case 'approval':
            case 'posting':
            case 'PADNJOapproval':
               if(this.editDetails){
                  this.Close()

               }
            break
            
               
         }
      this.form.date = this.HELPER.formatedDate()

      }
   }
}
</script>
<style scoped>
.payee{
   width: 300px;
}
.mobile {
   display: none !important;
}
select {
   width: 250px !important;
}

@media only screen and (max-width: 890px) {

    .con1 {
       flex-direction: column;
    }
    .ml-5 {
      margin-left: 0 !important;
      margin-top: 10px !important; 
    }

    .ks-body {
      margin: 10px !important;
      padding: 5px !important;
    }

    .datecon .cur{
      margin-left: 20% !important;
    }

    input[type=text]{
      width: 70%;
    }
    .name {
      display: none !important;
    }
    .web{
      display: none !important;
    }
    .mobile {
      display: flex !important;
   }
}
</style>